import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell, Text } from "@utopia/ui"

import type { recipientCell_BankTransferFragment$key } from "./__generated__/recipientCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment recipientCell_BankTransferFragment on OfframpTransfer {
    bankAccount {
      accountOwnerName
      bankName
    }
  }
`

interface BankTransfersTableProps {
  bankTransfer: recipientCell_BankTransferFragment$key
}

export function RecipientCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  return (
    <TableCell>
      <div className="flex flex-col">
        <Text>{bankTransferData.bankAccount.accountOwnerName}</Text>
        <Text size="sm" variant="lighter">
          {bankTransferData.bankAccount.bankName}
        </Text>
      </div>
    </TableCell>
  )
}
