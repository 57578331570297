import { Separator } from "@utopia/ui"

interface TopBarProps {
  children?: React.ReactNode
}

export function Topbar(props: TopBarProps) {
  return (
    <div className="flex h-[68px] w-full items-center border-b p-4">
      <div className="flex items-center">
        <img src="/utopia-text-logo.svg" alt="Utopia logo" className="h-8" />
      </div>
      <Separator orientation="vertical" className="mx-4" />
      {props.children}
    </div>
  )
}
