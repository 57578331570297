import { useLoader, useNavigate, useParams } from "@tanstack/react-router"
import { usePreloadedQuery } from "react-relay"
import { graphql } from "relay-runtime"

import { Button, Heading, Text } from "@utopia/ui"

import { Topbar } from "#components/topbar.js"

import type { bankTransfers_BankTransfersQuery } from "./__generated__/bankTransfers_BankTransfersQuery.graphql"
import { BankTransfersTable } from "./bank-transfers-new/components/bank-transfers-table/index.js"

const EmptyBankTransfers = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <img src="/utopia-bonsai.png" className="h-[240px] w-[240px]" alt="Utopia logo" />
      <Heading size="md"> You don't have any bank transfers... yet. </Heading>
      <Text>Bank transfers you successfully execute will show up here.</Text>
      <Button onClick={onClick} className="mt-2">
        Create bank transfer
      </Button>
    </div>
  )
}

export const BankTransfersQuery = graphql`
  query bankTransfers_BankTransfersQuery($workspaceSlug: String!) {
    offrampTransfers(workspaceSlug: $workspaceSlug) {
      id
      ...bankTransfersTable_BankTransferFragment
    }
  }
`

const fromRoute = "/authenticated-route/$slug/bank-transfers"

export const BankTransfers = () => {
  const bankTransfersQueryReference = useLoader({
    from: fromRoute,
  })

  const { slug } = useParams({
    from: fromRoute,
  })

  const bankTransfersData = usePreloadedQuery<bankTransfers_BankTransfersQuery>(
    BankTransfersQuery,
    bankTransfersQueryReference,
  )

  const navigate = useNavigate()

  const openBankTransferModal = async () => {
    await navigate({ to: "/$slug/bank-transfers/new", params: { slug } })
  }

  return (
    <div className="flex h-full w-full flex-col">
      <Topbar>
        <Button onClick={openBankTransferModal}>Create bank transfer</Button>
      </Topbar>
      {bankTransfersData.offrampTransfers.length > 0 ? (
        <div className="m-6">
          <BankTransfersTable bankTransfers={bankTransfersData.offrampTransfers} />
        </div>
      ) : (
        <EmptyBankTransfers onClick={openBankTransferModal} />
      )}
    </div>
  )
}
