import { Route, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { authenticatedRoute } from "#router.js"

import type { bankTransfersNew_BankAccountsQuery } from "./__generated__/bankTransfersNew_BankAccountsQuery.graphql.js"
import { BankAccountsQuery } from "./index.js"

export const bankTransfersNewRoute = new Route({
  getParentRoute: () => authenticatedRoute,
  path: "/$slug/bank-transfers/new",
  component: lazyRouteComponent(() => import("./index.js"), "BankTransfersNew"),
  loader: ({ params: { slug } }) => {
    return loadQuery<bankTransfersNew_BankAccountsQuery>(
      RelayEnvironment,
      BankAccountsQuery,
      {
        workspaceSlug: slug,
      },
    )
  },
})
