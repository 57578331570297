import { useState } from "react"
import { useNetwork, useSwitchNetwork } from "wagmi"

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@utopia/ui"

import { useToastAndError } from "#hooks/use-toast-and-error.js"

interface SwitchnetworkDialogProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  availableNetworks: {
    id: number
    name: string
  }[]
}

export function SwitchNetworkDialog({
  isOpen,
  onOpenChange,
  availableNetworks,
}: SwitchnetworkDialogProps) {
  const toastAndError = useToastAndError()
  const { chain } = useNetwork()
  const [selectedNetworkId, setSelectedNetworkId] = useState(chain?.id)
  const { switchNetworkAsync } = useSwitchNetwork()
  const switchNetwork = async () => {
    try {
      await switchNetworkAsync?.(selectedNetworkId)

      onOpenChange(false)
    } catch (error) {
      toastAndError("Failed to switch network", error)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Switch network to send transfer</DialogTitle>
        </DialogHeader>
        <Select
          defaultValue={chain?.id.toString()}
          onValueChange={(value) => setSelectedNetworkId(Number(value))}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a network" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {availableNetworks.map((availableNetwork) => (
                <SelectItem
                  key={availableNetwork.id}
                  value={availableNetwork.id.toString()}
                >
                  {availableNetwork.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <DialogFooter>
          <div className="grid w-full grid-flow-col justify-end gap-2">
            <Button
              onClick={() => onOpenChange(false)}
              variant="outline"
              colorScheme="stone"
            >
              Cancel
            </Button>
            <Button onClick={switchNetwork}>Switch network</Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
