import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Text } from "@utopia/ui"

import type { bankAccountDisplay_BankAccountFragment$key } from "./__generated__/bankAccountDisplay_BankAccountFragment.graphql"

const BankAccountFragment = graphql`
  fragment bankAccountDisplay_BankAccountFragment on BankAccount {
    bankName
    lastFourDigits
    accountOwnerName
  }
`

interface BankAccountDisplayProps {
  bankAccount: bankAccountDisplay_BankAccountFragment$key
}

export function BankAccountDisplay({ bankAccount }: BankAccountDisplayProps) {
  const bankAccountData = useFragment(BankAccountFragment, bankAccount)

  return (
    <div className="grid w-full">
      <Text>
        {bankAccountData.accountOwnerName}
        {/* 
                                No editing functionality currently for bank accounts
                                <Link
                                  className="pointer-events-auto"
                                  to="/$slug/bank-accounts"
                                  params={{ slug: workspaceSlug }}
                                >
                                  Edit
                                </Link> */}
      </Text>
      <Text size="sm">
        {bankAccountData.bankName} • {bankAccountData.lastFourDigits}
      </Text>
    </div>
  )
}
