/**
 * @generated SignedSource<<be801dd1a76dc6c4515ad4682675dc83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankTransfersTable_BankTransferFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"amountSentCell_BankTransferFragment" | "recipientCell_BankTransferFragment" | "sentAtCell_BankTransferFragment" | "statusCell_BankTransferFragment" | "transactionFeeCell_BankTransferFragment" | "walletCell_BankTransferFragment">;
  readonly " $fragmentType": "bankTransfersTable_BankTransferFragment";
}>;
export type bankTransfersTable_BankTransferFragment$key = ReadonlyArray<{
  readonly " $data"?: bankTransfersTable_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"bankTransfersTable_BankTransferFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "bankTransfersTable_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "walletCell_BankTransferFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "recipientCell_BankTransferFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "amountSentCell_BankTransferFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "transactionFeeCell_BankTransferFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "sentAtCell_BankTransferFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "statusCell_BankTransferFragment"
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "0bfdcfb2df61fd8295cfbbaa3614c12c";

export default node;
