import type { Session } from "@auth/core/types"
import { fetchQuery, graphql } from "relay-runtime"
import type { Simplify, TaggedUnion } from "type-fest"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

import { RelayEnvironment } from "#RelayEnvironment"

import type {
  userInfo_MeQuery,
  userInfo_MeQuery$data,
} from "./__generated__/userInfo_MeQuery.graphql"

export async function fetchData<T = unknown>(path: string): Promise<T | null> {
  const url = `/api/${path}`
  try {
    const options: RequestInit = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    const res = await fetch(url, options)
    const data = await res.json()
    if (!res.ok) throw data
    return Object.keys(data as object).length > 0 ? (data as T) : null // Return null if data empty
  } catch (error) {
    return null
  }
}

const MeQuery = graphql`
  query userInfo_MeQuery {
    me {
      user {
        id
        email
        name
        workspaces {
          id
          name
          slug
          customerId
        }
      }
    }
  }
`

const fetchUserInfo = async () => {
  if (await fetchData<Session>("auth/session")) {
    return fetchQuery<userInfo_MeQuery>(
      RelayEnvironment,
      MeQuery,
      {},
      {
        fetchPolicy: "network-only",
      },
    )
      .toPromise()
      .catch((data) => {
        if (data instanceof Object && "source" in data) {
          if (data.source.errors?.[0]?.extensions?.code === "UNAUTHORIZED") {
            return null
          }
        }
        throw data
      })
  }
  return null
}

type TaggedWithDefault<
  Tag extends string,
  Fields extends Record<string, unknown>,
  Default extends Record<string, unknown>,
> = Simplify<
  TaggedUnion<
    Tag,
    {
      [Property in keyof Fields]: Fields[Property] & Default
    }
  >
>

type ExtractAndOmitMeStore<U extends MeStore["state"]> = Omit<
  Extract<
    MeStore,
    {
      state: U
    }
  >,
  "selectOrganization" | "fetchUserInfo"
>

type MeStore = TaggedWithDefault<
  "state",
  {
    Authenticated: {
      me: userInfo_MeQuery$data["me"]
    }
    NotAuthenticated: { me: null }
    Init: { me: null }
  },
  {
    fetchUserInfo: () => Promise<
      ExtractAndOmitMeStore<"Authenticated"> | ExtractAndOmitMeStore<"NotAuthenticated">
    >
  }
>

export const useUserInfo = createWithEqualityFn<MeStore>((set) => {
  return {
    state: "Init",
    me: null,
    fetchUserInfo: async () => {
      const data = await fetchUserInfo()
      const calculateState = () => {
        if (data) {
          return {
            state: "Authenticated",
            me: data.me,
          } as const
        }
        return {
          state: "NotAuthenticated",
          me: null,
        } as const
      }
      const state = calculateState()
      set(state)
      return state
    },
  }
}, shallow)

export const useAuthenticatedUserInfo = () => {
  const userInfo = useUserInfo()
  if (userInfo.state === "Authenticated") {
    return userInfo
  }
  throw new Error(
    "User is not authenticated, you should only run this hook inside components that are suppose to be ran in authenticated context",
  )
}
