import { RainbowKitProvider, getDefaultWallets } from "@rainbow-me/rainbowkit"
import "@rainbow-me/rainbowkit/styles.css"
import { WagmiConfig, configureChains, createConfig } from "wagmi"
import { arbitrum, base, mainnet, optimism, polygon, zora } from "wagmi/chains"
import { publicProvider } from "wagmi/providers/public"

import { Config } from "#config.js"

const { chains, publicClient } = configureChains(
  [mainnet, polygon, optimism, arbitrum, base, zora],
  // TODO: Connect other providers
  [publicProvider()],
)

const { connectors } = getDefaultWallets({
  appName: "Utopia",
  projectId: Config.WALLET_CONNECT_PROJECT_ID || "52ce76ca7bd0c8d4b2a0b11608052897",
  chains,
})

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors,
  publicClient,
})

interface Web3ProviderProps {
  children: React.ReactNode
}

export const Web3Provider = ({ children }: Web3ProviderProps) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>{children}</RainbowKitProvider>
    </WagmiConfig>
  )
}
