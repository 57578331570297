import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import {
  Avatar,
  AvatarFallback,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@utopia/ui"

import type { bankAccountsTable_BankAccountFragment$key } from "./__generated__/bankAccountsTable_BankAccountFragment.graphql"

const BankAccountsFragment = graphql`
  fragment bankAccountsTable_BankAccountFragment on BankAccount @relay(plural: true) {
    id
    accountOwnerName
    bankName
    lastFourDigits
  }
`

interface BankAccountsTableProps {
  bankAccounts: bankAccountsTable_BankAccountFragment$key
}

const getInitials = (name: string) => {
  const words = name.split(" ")
  const initials = words.map((word) => word.charAt(0).toUpperCase())

  return initials.join("")
}

export function BankAccountsTable({ bankAccounts }: BankAccountsTableProps) {
  const bankAccountsData = useFragment(BankAccountsFragment, bankAccounts)

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Bank info</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {bankAccountsData.map((bankAccount) => (
          <TableRow key={bankAccount.id}>
            <TableCell className="flex flex-row items-center gap-2">
              <Avatar>
                <AvatarFallback>
                  {getInitials(bankAccount.accountOwnerName)}
                </AvatarFallback>
              </Avatar>
              {bankAccount.accountOwnerName}
            </TableCell>
            <TableCell>
              {bankAccount.bankName} • {bankAccount.lastFourDigits}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
