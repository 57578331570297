/**
 * @generated SignedSource<<650291036463f29c39e4e8718b88c75b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankAccountsTable_BankAccountFragment$data = ReadonlyArray<{
  readonly accountOwnerName: string;
  readonly bankName: string;
  readonly id: string;
  readonly lastFourDigits: string;
  readonly " $fragmentType": "bankAccountsTable_BankAccountFragment";
}>;
export type bankAccountsTable_BankAccountFragment$key = ReadonlyArray<{
  readonly " $data"?: bankAccountsTable_BankAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"bankAccountsTable_BankAccountFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "bankAccountsTable_BankAccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountOwnerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastFourDigits",
      "storageKey": null
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
};

(node as any).hash = "b42b42f28124dd94258166d58572586a";

export default node;
