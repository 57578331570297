import { useLoader, useNavigate, useParams, useSearch } from "@tanstack/react-router"
import { useCallback, useEffect, useState } from "react"
import { loadQuery, usePreloadedQuery } from "react-relay"
import { graphql } from "relay-runtime"

import { Button, Heading, Text, useToast } from "@utopia/ui"

import { RelayEnvironment } from "#RelayEnvironment"
import { Topbar } from "#components/topbar.js"
import { useAuthenticatedUserInfo } from "#store/user-info.js"

import type { bankAccounts_BankAccountsQuery } from "./__generated__/bankAccounts_BankAccountsQuery.graphql"
import { AddBankAccountDialog } from "./components/add-bank-account-dialog.js"
import { BankAccountsTable } from "./components/bank-accounts-table.js"

const EmptyBankAccounts = ({ onClick }: { onClick: () => void }) => {
  const {
    me: {
      user: { workspaces },
    },
  } = useAuthenticatedUserInfo()

  const { toast } = useToast()

  const addBankAccounts = useCallback(() => {
    if (!workspaces[0].customerId) {
      toast({
        description:
          "Workspace not verified. Please verify your workspace before adding bank accounts",
      })
    } else {
      onClick()
    }
  }, [onClick, toast, workspaces])

  return (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <img src="/utopia-koi.png" className="h-[240px] w-[240px]" alt="Utopia logo" />
      <Heading size="md"> You haven't added any bank accounts. </Heading>
      <Text size="md">
        Store bank account details so you can send bank transfers more easily.
      </Text>
      <Button onClick={addBankAccounts} className="mt-2">
        Add bank account
      </Button>
    </div>
  )
}

export const BankAccountsQuery = graphql`
  query bankAccounts_BankAccountsQuery($workspaceSlug: String!) {
    bankAccounts(workspaceSlug: $workspaceSlug) {
      ...bankAccountsTable_BankAccountFragment
    }
  }
`
const fromRoute = "/authenticated-route/$slug/bank-accounts"

export const BankAccounts = () => {
  const bankAccountsQueryReference = useLoader({
    from: fromRoute,
  })
  const { toast } = useToast()

  const {
    me: {
      user: { workspaces },
    },
  } = useAuthenticatedUserInfo()

  const { slug } = useParams({
    from: fromRoute,
  })

  const { addAccountForTransfer } = useSearch({ from: fromRoute }) as {
    addAccountForTransfer?: boolean
  }

  const bankAccountsData = usePreloadedQuery<bankAccounts_BankAccountsQuery>(
    BankAccountsQuery,
    bankAccountsQueryReference,
  )

  const refetchBankAccounts = useCallback(() => {
    const { variables } = bankAccountsQueryReference
    loadQuery(RelayEnvironment, BankAccountsQuery, variables, {
      fetchPolicy: "network-only",
    })
  }, [bankAccountsQueryReference])

  const [addBankAccountsOpen, setAddBankAccountsOpen] = useState(false)

  const openBankAccountsModal = useCallback(() => {
    if (!workspaces[0].customerId) {
      toast({
        description:
          "Workspace not verified. Please verify your workspace before adding bank accounts",
      })
    } else {
      setAddBankAccountsOpen(true)
    }
  }, [toast, workspaces])

  useEffect(() => {
    if (addAccountForTransfer === true) {
      openBankAccountsModal()
    }
  }, [addAccountForTransfer, openBankAccountsModal])

  // naviagte back to add transfers
  const navigate = useNavigate()

  const onBankAccountAdded = async () => {
    if (addAccountForTransfer === true) {
      await navigate({ to: "/$slug/bank-transfers/new", params: { slug } })
    }
  }

  return (
    <div className="flex h-full w-full flex-col">
      <Topbar>
        <Button onClick={openBankAccountsModal}>Add bank account</Button>
      </Topbar>
      {addBankAccountsOpen && (
        <AddBankAccountDialog
          workspaceSlug={slug}
          refetchBankAccounts={refetchBankAccounts}
          isOpen={addBankAccountsOpen}
          onOpenChange={setAddBankAccountsOpen}
          onBankAccountAdded={onBankAccountAdded}
        />
      )}
      {bankAccountsData.bankAccounts.length > 0 ? (
        <div className="m-6">
          <BankAccountsTable bankAccounts={bankAccountsData.bankAccounts} />
        </div>
      ) : (
        <EmptyBankAccounts onClick={openBankAccountsModal} />
      )}
    </div>
  )
}
