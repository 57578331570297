import { Route, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { sluggedRoute } from "#router.js"

import type { bankAccounts_BankAccountsQuery } from "./__generated__/bankAccounts_BankAccountsQuery.graphql.js"
import { BankAccountsQuery } from "./index.js"

export const bankAccountsRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/bank-accounts",
  wrapInSuspense: false,
  component: lazyRouteComponent(() => import("./index.js"), "BankAccounts"),
  loader: ({ params: { slug } }) => {
    return loadQuery<bankAccounts_BankAccountsQuery>(
      RelayEnvironment,
      BankAccountsQuery,
      {
        workspaceSlug: slug,
      },
    )
  },
})
