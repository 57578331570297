/**
 * @generated SignedSource<<a14ac4662ebe0a802785f2cbd8f4448b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FiatCurrencyType = "USD";
import { FragmentRefs } from "relay-runtime";
export type amountSentCell_BankTransferFragment$data = {
  readonly fiatCurrency: FiatCurrencyType;
  readonly networkId: number;
  readonly stableCoinAmountInCents: string;
  readonly tokenAddress: string;
  readonly " $fragmentType": "amountSentCell_BankTransferFragment";
};
export type amountSentCell_BankTransferFragment$key = {
  readonly " $data"?: amountSentCell_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"amountSentCell_BankTransferFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "amountSentCell_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stableCoinAmountInCents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fiatCurrency",
      "storageKey": null
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "5b8b86e3d722f951e78fe2a2e82ab221";

export default node;
