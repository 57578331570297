interface TopBarProps {
  children?: React.ReactNode
}

export function Topbar(props: TopBarProps) {
  return (
    <div className="flex h-16 w-full flex-row-reverse items-center border-b px-4 py-3">
      {props.children}
    </div>
  )
}
