import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { VerificationForm } from "#components/verification/verification-form.js"
import { useCreateKycStatus } from "#hooks/use-create-kyc-status.js"
import { useAuthenticatedUserInfo } from "#store/user-info.js"

import type { termsOfService_WorkspaceFragment$key } from "./__generated__/termsOfService_WorkspaceFragment.graphql"

const copyTextOptions = {
  individual: {
    heading: "Verify yourself to send bank transfers.",
    description:
      "All individuals can be verified, except those located in Arkansas, Florida, Louisiana, New York, as well as Belarus, Cuba, Iran, North Korea, Russia, and Syria.",
    submit: "Get started",
  },
  business: {
    heading: "Verify your business to send bank transfers.",
    description:
      "All businesses can be verified, except those located in Arkansas, Florida, Louisiana, New York, as well as Belarus, Cuba, Iran, North Korea, Russia, and Syria.",
    submit: "Continue",
  },
}

const WorkspaceFragment = graphql`
  fragment termsOfService_WorkspaceFragment on Workspace {
    id
    name
    workspaceType
  }
`

interface TermsOfServiceProps {
  workspace: termsOfService_WorkspaceFragment$key
  refreshKycStatus: VoidFunction
}

export const TermsOfService = ({ workspace, refreshKycStatus }: TermsOfServiceProps) => {
  const authenticatedUser = useAuthenticatedUserInfo()
  const workspaceData = useFragment(WorkspaceFragment, workspace)
  const copyText = copyTextOptions[workspaceData.workspaceType]

  const { tosModal, handleSubmit } = useCreateKycStatus({
    workspaceId: workspaceData?.id ?? "",
    onKycStatusCreated: () => {
      refreshKycStatus()
    },
  })

  return (
    <>
      {tosModal}
      <VerificationForm
        type={workspaceData.workspaceType}
        copyText={copyText}
        handleSubmit={handleSubmit}
        inputsReadOnly={workspaceData.workspaceType === "individual"}
        defaultValues={{
          name:
            workspaceData.workspaceType === "business"
              ? workspaceData.name
              : authenticatedUser.me.user.name ?? "",
          email: authenticatedUser.me.user.email,
        }}
      />
    </>
  )
}
