/**
 * @generated SignedSource<<6c8a9e0a0fadbb54963dc23ce8a3f850>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type walletCell_BankTransferFragment$data = {
  readonly walletAddress: string;
  readonly " $fragmentType": "walletCell_BankTransferFragment";
};
export type walletCell_BankTransferFragment$key = {
  readonly " $data"?: walletCell_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"walletCell_BankTransferFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "walletCell_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "walletAddress",
      "storageKey": null
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "5f69390cd520fb9708c6249f1af569ea";

export default node;
