import InlineSVG from "react-inlinesvg"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell, Text } from "@utopia/ui"

import type { transactionFeeCell_BankTransferFragment$key } from "./__generated__/transactionFeeCell_BankTransferFragment.graphql"
import { formatAmount, formatToken } from "./utils.js"

const BankTransferFragment = graphql`
  fragment transactionFeeCell_BankTransferFragment on OfframpTransfer {
    fee
    tokenAddress
    networkId
  }
`

interface BankTransfersTableProps {
  bankTransfer: transactionFeeCell_BankTransferFragment$key
}

export function TransactionFeeCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)
  const formattedAmount = formatAmount(bankTransferData.fee.toString())
  const formattedToken = formatToken({
    tokenAddress: bankTransferData.tokenAddress,
    networkId: bankTransferData.networkId,
  })

  return (
    <TableCell>
      <div className="flex items-center">
        <Text>{formattedAmount}</Text>
        {formattedToken && (
          <>
            <InlineSVG src={formattedToken.logoSrc} className="h-5 w-5 px-1" />{" "}
            {formattedToken.symbol}
          </>
        )}
      </div>
    </TableCell>
  )
}
