/**
 * @generated SignedSource<<2a4d5d605f83c404fc1fabf505c236c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankTransfers_BankTransfersQuery$variables = {
  workspaceSlug: string;
};
export type bankTransfers_BankTransfersQuery$data = {
  readonly offrampTransfers: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"bankTransfersTable_BankTransferFragment">;
  }>;
};
export type bankTransfers_BankTransfersQuery = {
  response: bankTransfers_BankTransfersQuery$data;
  variables: bankTransfers_BankTransfersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workspaceSlug",
    "variableName": "workspaceSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransfers_BankTransfersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfframpTransfer",
        "kind": "LinkedField",
        "name": "offrampTransfers",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "bankTransfersTable_BankTransferFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransfers_BankTransfersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfframpTransfer",
        "kind": "LinkedField",
        "name": "offrampTransfers",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "walletAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "bankAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountOwnerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bankName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stableCoinAmountInCents",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tokenAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "networkId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fiatCurrency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sentAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "858f406e22a1c15e26d55e0d1ce8ac28",
    "id": null,
    "metadata": {},
    "name": "bankTransfers_BankTransfersQuery",
    "operationKind": "query",
    "text": "query bankTransfers_BankTransfersQuery(\n  $workspaceSlug: String!\n) {\n  offrampTransfers(workspaceSlug: $workspaceSlug) {\n    id\n    ...bankTransfersTable_BankTransferFragment\n  }\n}\n\nfragment amountSentCell_BankTransferFragment on OfframpTransfer {\n  stableCoinAmountInCents\n  tokenAddress\n  networkId\n  fiatCurrency\n}\n\nfragment bankTransfersTable_BankTransferFragment on OfframpTransfer {\n  id\n  ...walletCell_BankTransferFragment\n  ...recipientCell_BankTransferFragment\n  ...amountSentCell_BankTransferFragment\n  ...transactionFeeCell_BankTransferFragment\n  ...sentAtCell_BankTransferFragment\n  ...statusCell_BankTransferFragment\n}\n\nfragment recipientCell_BankTransferFragment on OfframpTransfer {\n  bankAccount {\n    accountOwnerName\n    bankName\n  }\n}\n\nfragment sentAtCell_BankTransferFragment on OfframpTransfer {\n  sentAt\n}\n\nfragment statusCell_BankTransferFragment on OfframpTransfer {\n  status\n}\n\nfragment transactionFeeCell_BankTransferFragment on OfframpTransfer {\n  fee\n  tokenAddress\n  networkId\n}\n\nfragment walletCell_BankTransferFragment on OfframpTransfer {\n  walletAddress\n}\n"
  }
};
})();

(node as any).hash = "61e604f267a4b8987436ea299635b6dc";

export default node;
