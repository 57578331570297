import * as Sentry from "@sentry/react"
import { RouterProvider } from "@tanstack/react-router"
import { Analytics } from "@vercel/analytics/react"
import { ThemeProvider } from "next-themes"
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { RelayEnvironmentProvider } from "react-relay"

import { Toaster } from "@utopia/ui"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { Web3Provider } from "#providers/web3.js"
import { createRouter } from "#router.js"
import "#services/sentry.js"
import { useUserInfo } from "#store/user-info.js"

import "./globals.css"

function loadHubSpotScript() {
  const script = document.createElement("script")
  script.src = "//js.hs-scripts.com/42161454.js" // Replace with your HubSpot ID.
  script.async = true
  script.defer = true
  script.id = "hs-script-loader"
  document.body.appendChild(script)
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

// Load Hubspot Chat Script
loadHubSpotScript()

void useUserInfo
  .getState()
  .fetchUserInfo()
  .then(() => {
    root.render(
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <ThemeProvider attribute="class" defaultTheme="light">
          <Analytics />
          <Suspense>
            <Toaster />
            <Web3Provider>
              <RouterProvider router={createRouter()} />
            </Web3Provider>
          </Suspense>
        </ThemeProvider>
      </RelayEnvironmentProvider>,
    )
  })
  .catch((err) => {
    Sentry.captureException(err)
    root.render(
      <React.StrictMode>
        <ThemeProvider attribute="class">
          <Analytics />
          <div className="h-[100vh] w-[100vw] text-center">
            Something wrong has happened, please refresh the page
          </div>
        </ThemeProvider>
      </React.StrictMode>,
    )
  })
