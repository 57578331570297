import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Text,
} from "@utopia/ui"

import type {
  AccountVerificationSchema,
  VerificationType,
} from "#utils/account-verification.js"
import { accountVerificationSchema } from "#utils/account-verification.js"

const formLabelOptions = {
  individual: {
    name: "Name",
    email: "Email",
  },
  business: {
    name: "Business name",
    email: "Business email",
  },
}

interface VerificationFormProps {
  type: VerificationType
  copyText: {
    heading: string
    description?: React.ReactNode
    submit: string
  }
  defaultValues?: AccountVerificationSchema
  handleSubmit?: (data: AccountVerificationSchema) => void
  inputsReadOnly?: boolean
}

export const VerificationForm = ({
  type,
  copyText,
  defaultValues,
  handleSubmit,
  inputsReadOnly,
}: VerificationFormProps) => {
  const formLabels = formLabelOptions[type]

  const form = useForm<AccountVerificationSchema>({
    resolver: zodResolver(accountVerificationSchema),
    mode: "onChange",
    defaultValues: defaultValues ?? {
      name: "",
      email: "",
    },
  })

  const onSubmit =
    handleSubmit &&
    form.handleSubmit(async (data) => {
      handleSubmit(data)
    })

  return (
    <div className="flex flex-col">
      <Heading className="font-semibold leading-8" size="md">
        {copyText.heading}
      </Heading>
      {copyText.description && (
        <div className="mt-2">
          {typeof copyText.description === "string" ? (
            <Text size="lg">{copyText.description}</Text>
          ) : (
            copyText.description
          )}
        </div>
      )}
      <Form {...form}>
        <form className="mt-6 grid w-full gap-4" onSubmit={onSubmit}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{formLabels.name}</FormLabel>
                <FormControl>
                  <Input
                    className="h-[52px]"
                    {...field}
                    placeholder="Enter name"
                    disabled={inputsReadOnly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{formLabels.email}</FormLabel>
                <FormControl>
                  <Input
                    className="h-[52px]"
                    {...field}
                    placeholder="Enter email"
                    disabled={inputsReadOnly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {onSubmit && (
            <Button className="mt-2 h-[52px] w-full" type="submit">
              {copyText.submit}
            </Button>
          )}
        </form>
      </Form>
    </div>
  )
}
