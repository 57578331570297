import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell, Text } from "@utopia/ui"

import type { sentAtCell_BankTransferFragment$key } from "./__generated__/sentAtCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment sentAtCell_BankTransferFragment on OfframpTransfer {
    sentAt
  }
`

interface BankTransfersTableProps {
  bankTransfer: sentAtCell_BankTransferFragment$key
}

export function SentAtCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  return (
    <TableCell>
      <Text>{bankTransferData.sentAt}</Text>
    </TableCell>
  )
}
