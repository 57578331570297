import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { graphql } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  Heading,
  Separator,
  useToast,
} from "@utopia/ui"

import { usePromisifiedMutation } from "#hooks/use-promisified-mutation.js"
import { useToastAndError } from "#hooks/use-toast-and-error.js"

import type { addBankAccountDialog_AddBankAccountMutation } from "./__generated__/addBankAccountDialog_AddBankAccountMutation.graphql"
import { AddBankAccountForm } from "./add-bank-account-form.js"
import { addBankAccountSchema } from "./add-bank-account-schema.js"

const AddBankAccountMutation = graphql`
  mutation addBankAccountDialog_AddBankAccountMutation(
    $input: BankAccountCreateInput!
    $workspaceSlug: String!
  ) {
    bankAccountCreate(input: $input, workspaceSlug: $workspaceSlug) {
      ...bankAccountsTable_BankAccountFragment
    }
  }
`

interface AddBankAccountDialogProps {
  workspaceSlug: string
  refetchBankAccounts: VoidFunction
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onBankAccountAdded: VoidFunction
}

export const AddBankAccountDialog = ({
  workspaceSlug,
  refetchBankAccounts,
  isOpen,
  onOpenChange,
  onBankAccountAdded,
}: AddBankAccountDialogProps) => {
  const { toast } = useToast()
  const toastAndError = useToastAndError()
  const form = useForm({
    resolver: zodResolver(addBankAccountSchema),
    mode: "onChange",
    defaultValues: {
      accountOwnerName: "",
      accountNumber: "",
      accountName: "",
      routingNumber: "",
      fullAddress: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      city: "",
      state: "",
      zip: "",
      bankName: "",
    },
  })

  const [addBankAccount] =
    usePromisifiedMutation<addBankAccountDialog_AddBankAccountMutation>(
      AddBankAccountMutation,
    )

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const input = {
        idempotencyKey: uuidv4(),
        ...(data.country === "USA"
          ? {
              address: {
                city: data.city,
                country: data.country,
                postalCode: data.zip,
                state: data.state,
                streetLine1: data.addressLine1,
                streetLine2: data.addressLine2,
              },
            }
          : {}),
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        bankName: data.bankName,
        accountOwnerName: data.accountOwnerName,
      }

      await addBankAccount({
        variables: {
          input,
          workspaceSlug,
        },
      })
      refetchBankAccounts()

      toast({
        title: `${
          data.accountName || data.accountOwnerName
        }'s bank account has been added.`,
      })

      // Checks if bank account was added successfully and navigate if query params are set
      onBankAccountAdded()

      onOpenChange(false)
    } catch (error) {
      toastAndError("Error creating bank account", error)
    }
  })

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="min-w-[600px] gap-4 p-0">
        <Form {...form}>
          <form onSubmit={onSubmit}>
            <DialogHeader className="p-4">
              <DialogTitle>
                <Heading size="md">Add bank account</Heading>
              </DialogTitle>
            </DialogHeader>
            <Separator />
            <div className="px-4">
              <AddBankAccountForm />
            </div>
            <Separator />
            <DialogFooter className="h-[84px] items-center px-4">
              <Button
                className="h-[52px] w-full"
                type="button"
                variant="outline"
                onClick={() => onOpenChange(false)}
              >
                Close
              </Button>
              <Button
                className="h-[52px] w-full"
                type="submit"
                isLoading={form.formState.isSubmitting}
              >
                Add
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
