import { useMemo } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Badge, TableCell } from "@utopia/ui"

import type { statusCell_BankTransferFragment$key } from "./__generated__/statusCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment statusCell_BankTransferFragment on OfframpTransfer {
    status
  }
`

interface BankTransfersTableProps {
  bankTransfer: statusCell_BankTransferFragment$key
}

export function StatusCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  const colorVariants = {
    saffron: "text-saffron-700 bg-saffron-100",
    earth: "text-earth-700 bg-earth-100",
    sunset: "text-sunset-700 bg-sunset-100",
  }

  const formattedStatus = useMemo(() => {
    switch (bankTransferData.status) {
      case "awaiting_funds":
        return { status: "Pending", colorScheme: "saffron" } as const
      case "payment_posted":
        return { status: "Completed", colorScheme: "earth" } as const
      case "error":
      case "canceled":
        return { status: "Failed", colorScheme: "sunset" } as const
      case "funds_received":
      case "payment_submitted":
        return { status: "In progress", colorScheme: "saffron" } as const
      default:
        return { status: "Unknown", colorScheme: "sunset" } as const
    }
  }, [bankTransferData.status])

  return (
    <TableCell>
      <Badge className={colorVariants[formattedStatus.colorScheme]}>
        {formattedStatus.status}
      </Badge>
    </TableCell>
  )
}
