import { useEffect } from "react"
import InlineSVG from "react-inlinesvg"

import { Text, useToast } from "@utopia/ui"

interface Props {
  text?: string
  width?: number
  height?: number
  timeout?: number
  hideErrorOnTimeout?: boolean
  children?: React.ReactNode
}

const DEFAULT_TIMEOUT = 15000

export const Loading = ({
  text,
  children,
  width,
  height,
  timeout: loadingTimeout,
  hideErrorOnTimeout = false,
  ...props
}: Props) => {
  const { toast } = useToast()
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!hideErrorOnTimeout) {
        toast({
          description:
            "Something went wrong (timeout). Please try again or contact Utopia",
        })
      }
    }, loadingTimeout ?? DEFAULT_TIMEOUT)

    return () => clearTimeout(timeout)
  }, [toast, hideErrorOnTimeout, loadingTimeout])

  return (
    <div
      className="flex w-full flex-col items-center justify-center"
      data-testid="loading-dots"
      {...props}
    >
      {children}
      <InlineSVG
        fill="#659484" // TODO - replace with color from theme (earth.500)
        width={width || 120} // 120 is a default width of the loader
        height={height || 30} // 30 is a default height of the loader
        src="/loading.svg"
      />
      {text && <Text size="sm">{text}</Text>}
    </div>
  )
}
