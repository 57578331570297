/**
 * @generated SignedSource<<0ccb8eddf1e5a49ab414e90b887d769e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BridgeKycStatus = "approved" | "manual_review" | "pending" | "rejected";
export type verification_WorkspaceQuery$variables = {
  workspaceSlug: string;
};
export type verification_WorkspaceQuery$data = {
  readonly workspace: {
    readonly id: string;
    readonly kycStatus: {
      readonly status: BridgeKycStatus;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"approvedVerificationForm_WorkspaceFragment" | "inReviewVerificationForm_WorkspaceFragment" | "personaVerification_WorkspaceFragment" | "rejectedVerificationForm_WorkspaceFragment" | "termsOfService_WorkspaceFragment">;
  };
};
export type verification_WorkspaceQuery = {
  response: verification_WorkspaceQuery$data;
  variables: verification_WorkspaceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workspaceSlug",
    "variableName": "workspaceSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "verification_WorkspaceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KycStatus",
            "kind": "LinkedField",
            "name": "kycStatus",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "termsOfService_WorkspaceFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "inReviewVerificationForm_WorkspaceFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "rejectedVerificationForm_WorkspaceFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "approvedVerificationForm_WorkspaceFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "personaVerification_WorkspaceFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "verification_WorkspaceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KycStatus",
            "kind": "LinkedField",
            "name": "kycStatus",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kycUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workspaceType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "512b8fd2679d618388d7178ee0b7592f",
    "id": null,
    "metadata": {},
    "name": "verification_WorkspaceQuery",
    "operationKind": "query",
    "text": "query verification_WorkspaceQuery(\n  $workspaceSlug: String!\n) {\n  workspace(workspaceSlug: $workspaceSlug) {\n    id\n    kycStatus {\n      status\n    }\n    ...termsOfService_WorkspaceFragment\n    ...inReviewVerificationForm_WorkspaceFragment\n    ...rejectedVerificationForm_WorkspaceFragment\n    ...approvedVerificationForm_WorkspaceFragment\n    ...personaVerification_WorkspaceFragment\n  }\n}\n\nfragment approvedVerificationForm_WorkspaceFragment on Workspace {\n  workspaceType\n  kycStatus {\n    email\n    name\n  }\n}\n\nfragment inReviewVerificationForm_WorkspaceFragment on Workspace {\n  workspaceType\n  kycStatus {\n    email\n    name\n  }\n}\n\nfragment personaVerification_WorkspaceFragment on Workspace {\n  workspaceType\n  kycStatus {\n    kycUrl\n  }\n}\n\nfragment rejectedVerificationForm_WorkspaceFragment on Workspace {\n  workspaceType\n  kycStatus {\n    email\n    name\n  }\n}\n\nfragment termsOfService_WorkspaceFragment on Workspace {\n  id\n  name\n  workspaceType\n}\n"
  }
};
})();

(node as any).hash = "df341f3df32243f240c4980d08ee821d";

export default node;
