import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined"
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined"
import { Icon } from "@mui/material"
import { Link, Outlet, useParams } from "@tanstack/react-router"
import { signOut } from "fastify-next-auth/client"
import { Suspense, useCallback } from "react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Text,
} from "@utopia/ui"

import { useAuthenticatedUserInfo } from "#store/user-info.js"

import { Loading } from "./loading.js"

const navbarItems = [
  {
    title: "Bank transfers",
    icon: PaymentsOutlinedIcon,
    link: "/$slug/bank-transfers",
  },
  {
    title: "Bank accounts",
    icon: ContactsOutlinedIcon,
    link: "/$slug/bank-accounts",
  },
  {
    title: "Verification",
    icon: VerifiedOutlinedIcon,
    link: "/$slug/verification",
  },
] as const

export function WorkspaceLayout() {
  const userInfo = useAuthenticatedUserInfo()
  const { slug } = useParams({
    from: "/authenticated-route/$slug",
  })

  const handleLogOut = useCallback(() => {
    void signOut({
      callbackUrl: `${window.origin}/login`,
    })
  }, [])

  return (
    <div className="flex min-h-screen">
      <div className="flex min-w-[200px] flex-col items-start justify-start border-r">
        <div className="flex h-16 w-full items-center justify-start border-b p-4">
          <div className="h-8 w-[122.18px]">
            <img className="h-8" src="/utopia-text-logo.svg" alt="Utopia logo" />
          </div>
        </div>
        <div className="flex w-full grow basis-0 flex-col items-center justify-start gap-4 py-4">
          <div className="flex w-full flex-col items-start">
            {navbarItems.map((item) => (
              <Link
                key={item.title}
                to={item.link}
                params={{ slug }}
                className="h-[44px] w-full"
              >
                <div className="flex w-full items-center gap-2 px-4 py-3 hover:bg-stone-100">
                  <Icon className="h-5 w-5 text-stone-400" component={item.icon} />
                  <Text>{item.title}</Text>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger className="flex w-full border-t p-4 text-start">
            <div className="flex shrink grow basis-0 flex-col ">
              <Text>{userInfo.me.user.name}</Text>
              <Text size="sm" variant="lighter">
                {userInfo.me.user.workspaces[0].name}
              </Text>
            </div>
            <Icon
              className="h-8 w-8 text-stone-400"
              component={KeyboardArrowDownOutlinedIcon}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[184px]">
            <DropdownMenuItem
              className="h-[44px] px-4 py-3 hover:bg-stone-100"
              onClick={handleLogOut}
            >
              <div className="flex w-full items-center gap-2">
                <Icon className="h-5 w-5 text-stone-400" component={LogoutOutlinedIcon} />
                Sign out
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="flex w-full">
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}
