import type { HubSpotConversationsType } from "#types/hubspot-conversations.js"

export function ContactSupportLink() {
  const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      ;(
        window as { HubSpotConversations?: HubSpotConversationsType }
      ).HubSpotConversations?.widget.open()
    }
  }

  return (
    <span
      className="text-earth-700 underline"
      onClick={() =>
        (
          window as { HubSpotConversations?: HubSpotConversationsType }
        ).HubSpotConversations?.widget.open()
      }
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      contact support
    </span>
  )
}
