/**
 * @generated SignedSource<<d0980541679cb5ce977d380947cd39c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type transactionFeeCell_BankTransferFragment$data = {
  readonly fee: number;
  readonly networkId: number;
  readonly tokenAddress: string;
  readonly " $fragmentType": "transactionFeeCell_BankTransferFragment";
};
export type transactionFeeCell_BankTransferFragment$key = {
  readonly " $data"?: transactionFeeCell_BankTransferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"transactionFeeCell_BankTransferFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "transactionFeeCell_BankTransferFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkId",
      "storageKey": null
    }
  ],
  "type": "OfframpTransfer",
  "abstractKey": null
};

(node as any).hash = "53a3360f6bcb34c02f6143cc21cc94f6";

export default node;
