/**
 * @generated SignedSource<<f2810edf8afa65232ac7ed43c24bd7b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankTransfersNew_BankAccountsQuery$variables = {
  workspaceSlug: string;
};
export type bankTransfersNew_BankAccountsQuery$data = {
  readonly bankAccounts: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"bankAccountDisplay_BankAccountFragment">;
  }>;
};
export type bankTransfersNew_BankAccountsQuery = {
  response: bankTransfersNew_BankAccountsQuery$data;
  variables: bankTransfersNew_BankAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workspaceSlug",
    "variableName": "workspaceSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransfersNew_BankAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BankAccount",
        "kind": "LinkedField",
        "name": "bankAccounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "bankAccountDisplay_BankAccountFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransfersNew_BankAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BankAccount",
        "kind": "LinkedField",
        "name": "bankAccounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bankName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastFourDigits",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountOwnerName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b3b748561deb5e38bf01a80179ae282",
    "id": null,
    "metadata": {},
    "name": "bankTransfersNew_BankAccountsQuery",
    "operationKind": "query",
    "text": "query bankTransfersNew_BankAccountsQuery(\n  $workspaceSlug: String!\n) {\n  bankAccounts(workspaceSlug: $workspaceSlug) {\n    id\n    ...bankAccountDisplay_BankAccountFragment\n  }\n}\n\nfragment bankAccountDisplay_BankAccountFragment on BankAccount {\n  bankName\n  lastFourDigits\n  accountOwnerName\n}\n"
  }
};
})();

(node as any).hash = "90cbc04e4c62b0389315851879f44e3e";

export default node;
