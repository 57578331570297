import {
  Text,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from "@utopia/ui"

import { shortenWalletAddress } from "#utils/shorten-wallet-address.js"

interface ShortenedWalletAddressProps {
  walletAddress: string
}

export function ShortenedWalletAddress({ walletAddress }: ShortenedWalletAddressProps) {
  const { toast } = useToast()
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(walletAddress)
    toast({
      title: "Copied to clipboard",
    })
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Text onClick={copyToClipboard}>{shortenWalletAddress(walletAddress)}</Text>
        </TooltipTrigger>
        <TooltipContent>
          <Text>{walletAddress}</Text>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
