import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell } from "@utopia/ui"

import { ShortenedWalletAddress } from "#components/shortened-wallet-address.js"

import type { walletCell_BankTransferFragment$key } from "./__generated__/walletCell_BankTransferFragment.graphql"

const BankTransferFragment = graphql`
  fragment walletCell_BankTransferFragment on OfframpTransfer {
    walletAddress
  }
`

interface BankTransfersTableProps {
  bankTransfer: walletCell_BankTransferFragment$key
}

export function WalletCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)

  return (
    <TableCell>
      <ShortenedWalletAddress walletAddress={bankTransferData.walletAddress} />
    </TableCell>
  )
}
