import { zodResolver } from "@hookform/resolvers/zod"
import WarningAmberIcon from "@mui/icons-material/WarningAmberRounded"
import { Icon } from "@mui/material"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { useLoader, useNavigate, useParams } from "@tanstack/react-router"
import { ethers } from "ethers"
import { useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import InlineSVG from "react-inlinesvg"
import { graphql, usePreloadedQuery } from "react-relay"
import { v4 as uuidv4 } from "uuid"
import { useAccount, useNetwork, useSendTransaction } from "wagmi"
import { z } from "zod"

import {
  NetworkIds,
  allNetworks,
  chainIdToUsdcAddress,
  chainIdToUsdtAddress,
} from "@utopia/evm"
import {
  type CurrencyFeeType,
  calculateAmountWithTotalFeeInCents,
  formatCentsToDollars,
  formatDollarsToCents,
} from "@utopia/fees"
import {
  Alert,
  AlertTitle,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from "@utopia/ui"

import { usePromisifiedMutation } from "#hooks/use-promisified-mutation.js"
import { useToastAndError } from "#hooks/use-toast-and-error.js"

import type { bankTransfersNew_BankAccountsQuery } from "./__generated__/bankTransfersNew_BankAccountsQuery.graphql"
import type { bankTransfersNew_CompleteBankTransferMutation } from "./__generated__/bankTransfersNew_CompleteBankTransferMutation.graphql"
import type { bankTransfersNew_CreateBankTransferMutation } from "./__generated__/bankTransfersNew_CreateBankTransferMutation.graphql"
import { BankAccountDisplay } from "./components/bank-account-display.js"
import { SwitchNetworkDialog } from "./components/switch-network-dialog.js"
import { Topbar } from "./components/topbar.js"

const transferABI = [
  {
    name: "transfer",
    type: "function",
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        type: "uint256",
        name: "_tokens",
      },
    ],
    constant: false,
    outputs: [],
    payable: false,
  },
]

const stablecoins = [
  {
    logoSrc: "/usdc.svg",
    name: "USDC",
  },
  {
    logoSrc: "/usdt.svg",
    name: "USDT",
  },
]

const schema = z.object({
  amount: z
    .string()
    .regex(/^\d{1,}(\.\d{1,2})?$/, { message: "Amount has to be a valid dollar amount" })
    .refine((value) => parseFloat(value) >= 1, {
      message: "Amount must be greater than 1",
    }),
  bankAccountId: z.string(),
  stablecoin: z.object({
    logoSrc: z.string(),
    name: z.string(),
  }),
})

export const BankAccountsQuery = graphql`
  query bankTransfersNew_BankAccountsQuery($workspaceSlug: String!) {
    bankAccounts(workspaceSlug: $workspaceSlug) {
      id
      ...bankAccountDisplay_BankAccountFragment
    }
  }
`

const CreateBankTransferMutation = graphql`
  mutation bankTransfersNew_CreateBankTransferMutation(
    $idempotencyKey: String!
    $transferDetails: OfframpTransferCreateInput!
    $workspaceSlug: String!
  ) {
    offrampTransferCreate(
      idempotencyKey: $idempotencyKey
      transferDetails: $transferDetails
      workspaceSlug: $workspaceSlug
    ) {
      id
      destinationAddress
    }
  }
`
const CompleteBankTransferMutation = graphql`
  mutation bankTransfersNew_CompleteBankTransferMutation(
    $id: CUID2!
    $input: OfframpTransferCompleteInput!
    $workspaceSlug: String!
  ) {
    offrampTransferComplete(id: $id, input: $input, workspaceSlug: $workspaceSlug) {
      id
    }
  }
`

const fromRoute = "/authenticated-route/$slug/bank-transfers/new"

export const BankTransfersNew = () => {
  const [createBankTransfer] =
    usePromisifiedMutation<bankTransfersNew_CreateBankTransferMutation>(
      CreateBankTransferMutation,
    )

  const [completeBankTransfer] =
    usePromisifiedMutation<bankTransfersNew_CompleteBankTransferMutation>(
      CompleteBankTransferMutation,
    )

  const { slug: workspaceSlug } = useParams({
    from: fromRoute,
  })

  const bankAccountsQueryReference = useLoader({
    from: fromRoute,
  })

  const bankAccountsData = usePreloadedQuery<bankTransfersNew_BankAccountsQuery>(
    BankAccountsQuery,
    bankAccountsQueryReference,
  )

  const bankAccounts = bankAccountsData.bankAccounts

  const [chainModalOpen, setChainModalOpen] = useState(false)
  const toastAndError = useToastAndError()
  const { openConnectModal } = useConnectModal()
  const { sendTransactionAsync } = useSendTransaction()
  const { chain } = useNetwork()
  const { isConnected, address } = useAccount()

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: "1",
      bankAccountId: bankAccounts[0]?.id ?? "",
      stablecoin: stablecoins[0],
    },
  })

  const currentAmount = form.watch("amount")
  const currentStablecoin = form.watch("stablecoin")

  const transactionCostCents = calculateAmountWithTotalFeeInCents({
    type: "businessOffRamp",
    amountInCents: formatDollarsToCents(currentAmount),
    currency: currentStablecoin.name.toLowerCase() as CurrencyFeeType,
  })

  const transactionCost = formatCentsToDollars(transactionCostCents)

  const availableNetworks = useMemo(() => {
    return [
      allNetworks[NetworkIds.Mainnet],
      ...(currentStablecoin.name === "USDC"
        ? [allNetworks[NetworkIds.Polygon], allNetworks[NetworkIds.Avalanche]]
        : []),
    ]
  }, [currentStablecoin.name])

  const navigate = useNavigate()

  const moveToTransferList = async () => {
    await navigate({ to: "/$slug/bank-transfers", params: { slug: workspaceSlug } })
  }

  const handleAddBankAccount = useCallback(async () => {
    await navigate({
      to: "/$slug/bank-accounts",
      params: { slug: workspaceSlug },
      search: { addAccountForTransfer: true },
    })
  }, [navigate, workspaceSlug])

  const sendBankTransfer = form.handleSubmit(async (formData) => {
    if (!isConnected || !address || !chain?.id || !formData.bankAccountId) {
      return
    }

    const isEthereumNetwork = chain.id === NetworkIds.Mainnet
    const isPolygonNetwork = chain.id === NetworkIds.Polygon
    const isAvaxNetwork = chain.id === NetworkIds.Avalanche

    if (formData.stablecoin.name === "USDC") {
      if (!isEthereumNetwork && !isPolygonNetwork && !isAvaxNetwork) {
        setChainModalOpen(true)
        return
      }
    }

    if (formData.stablecoin.name === "USDT") {
      if (!isEthereumNetwork) {
        setChainModalOpen(true)
        return
      }
    }

    const tokenAddress =
      currentStablecoin.name === "USDC"
        ? chainIdToUsdcAddress[chain.id]
        : chainIdToUsdtAddress[chain.id]

    try {
      const createResponse = await createBankTransfer({
        variables: {
          idempotencyKey: uuidv4(),
          workspaceSlug,
          transferDetails: {
            stableCoinAmountInCents: transactionCostCents.toString(),
            bankAccountId: formData.bankAccountId,
            tokenAddress,
            walletAddress: address,
            networkId: chain.id,
          },
        },
      })

      const token = new ethers.Contract(tokenAddress, transferABI)
      const amount = ethers.parseUnits(transactionCost, 6)

      const encodedData = token.interface.encodeFunctionData("transfer", [
        createResponse.offrampTransferCreate.destinationAddress,
        amount,
      ])

      const txResponse = await sendTransactionAsync({
        to: tokenAddress,
        chainId: chain.id,
        value: ethers.toBigInt("0"),
        data: encodedData as `0x${string}`,
      })

      await completeBankTransfer({
        variables: {
          id: createResponse.offrampTransferCreate.id,
          input: {
            hash: txResponse.hash,
            address,
          },
          workspaceSlug,
        },
      })

      await moveToTransferList()
    } catch (error) {
      toastAndError("Failed to create bank transfer", error)
    }
  })

  return (
    <div className="flex min-h-screen flex-col">
      <SwitchNetworkDialog
        isOpen={chainModalOpen}
        onOpenChange={setChainModalOpen}
        availableNetworks={availableNetworks}
      />
      <Topbar>
        <div className="grid w-full grid-flow-col items-center justify-between">
          <Text className="font-medium">Create bank transfer</Text>
          <div className="flex gap-4">
            <Button colorScheme="stone" variant="outline" onClick={moveToTransferList}>
              Discard
            </Button>
            <Button
              isLoading={form.formState.isSubmitting}
              type={isConnected ? "submit" : "button"}
              onClick={() => (!isConnected ? openConnectModal?.() : null)}
            >
              {isConnected
                ? "Send bank transfer"
                : "Connect wallet to send bank transfer"}
            </Button>
          </div>
        </div>
      </Topbar>
      <Form {...form}>
        <form
          className="flex h-full w-full flex-col items-center"
          onSubmit={sendBankTransfer}
          noValidate
        >
          <div className="flex w-[668px] flex-col pt-10">
            <Heading className="font-semibold leading-8" size="md">
              Bank transfer details
            </Heading>
            <div className="mt-6 grid gap-4">
              <FormField
                control={form.control}
                name="bankAccountId"
                render={({ field: { onChange, value } }) => {
                  const selectedBankAccount = bankAccounts.find((b) => b.id === value)

                  return (
                    <FormItem>
                      <FormLabel>Transfer to</FormLabel>
                      <Select value={value} onValueChange={onChange}>
                        <FormControl>
                          <SelectTrigger className="h-[70px] w-full p-4">
                            <SelectValue>
                              {selectedBankAccount ? (
                                <BankAccountDisplay bankAccount={selectedBankAccount} />
                              ) : (
                                "Select a bank account"
                              )}
                            </SelectValue>
                          </SelectTrigger>
                        </FormControl>

                        <SelectContent>
                          <SelectGroup>
                            {bankAccounts.map((bankAccount) => (
                              <SelectItem key={bankAccount.id} value={bankAccount.id}>
                                <BankAccountDisplay bankAccount={bankAccount} />
                              </SelectItem>
                            ))}
                          </SelectGroup>
                          <Button
                            variant="ghost"
                            className="flex w-full justify-start"
                            onClick={handleAddBankAccount}
                          >
                            + Add bank account
                          </Button>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Amount</FormLabel>
                    <div className="grid auto-cols-[1fr_1fr] grid-flow-col">
                      <Input
                        className="h-[52px] rounded-r-none"
                        {...field}
                        placeholder="Please enter an amount"
                      />
                      <div className="grid grid-flow-col items-center justify-start gap-1 rounded-r-md border border-l-0 border-solid border-stone-200 px-4 py-3">
                        <InlineSVG src="/usd.svg" className="h-5 w-5 p-1" />
                        <Text size="md">USD</Text>
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="stablecoin"
                render={({ field: { onChange, value } }) => (
                  <FormItem>
                    <FormLabel>Transaction cost</FormLabel>
                    <div className="grid auto-cols-[1fr_1fr] grid-flow-col">
                      <Input
                        className="h-[52px] rounded-r-none opacity-100"
                        value={transactionCost}
                        type="number"
                        placeholder="Amount to send"
                        disabled
                      />
                      <Select
                        value={value.name}
                        onValueChange={(v) =>
                          onChange(stablecoins.find((c) => c.name === v))
                        }
                      >
                        <FormControl>
                          <SelectTrigger className="h-[52px] rounded-l-none">
                            <SelectValue>
                              <div className="grid grid-flow-col items-center justify-start gap-1">
                                <InlineSVG src={value.logoSrc} className="h-5 w-5 p-1" />
                                <Text>{value.name}</Text>
                              </div>
                            </SelectValue>
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            {stablecoins.map((stablecoin) => (
                              <SelectItem key={stablecoin.name} value={stablecoin.name}>
                                <div className="grid grid-flow-col items-center justify-start gap-1">
                                  <InlineSVG
                                    src={stablecoin.logoSrc}
                                    className="h-5 w-5 px-1 py-[4px]"
                                  />
                                  <Text>{stablecoin.name}</Text>
                                </div>
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <FormMessage />
                    <Alert className="bg-saffron-50 !mt-0 h-[52px] rounded-t-none">
                      <AlertTitle className="flex items-center gap-2">
                        <Icon
                          className="text-saffron-500 !h-5 !w-5"
                          component={WarningAmberIcon}
                        />
                        <Text>{`We’ve added a 0.3% transaction fee to convert ${currentStablecoin.name} into USD.`}</Text>
                      </AlertTitle>
                    </Alert>
                  </FormItem>
                )}
              />
            </div>

            <div className="mt-6 flex flex-col gap-4">
              <Button
                className="h-[52px]"
                isLoading={form.formState.isSubmitting}
                type={isConnected ? "submit" : "button"}
                onClick={() => (!isConnected ? openConnectModal?.() : null)}
              >
                {isConnected
                  ? "Send bank transfer"
                  : "Connect wallet to send bank transfer"}
              </Button>
              <Button className="h-[52px]" variant="outline" onClick={moveToTransferList}>
                Discard
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}
