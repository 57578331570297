import * as Sentry from "@sentry/react"

import { Config } from "#config.js"

if (Config.isProductionDeploy) {
  Sentry.init({
    dsn: "https://20563720c426d20aded53c44eca9ce9b@o1191150.ingest.sentry.io/4505996271878144",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        // We need to possibly here add routing instrumentation
      }),
      new Sentry.Replay(),
    ],
    release: Config.VITE_FC_GIT_COMMIT_SHA,
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
