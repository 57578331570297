/**
 * @generated SignedSource<<0b23ec9e4e941c642fe5b5fc7b85602b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bankAccountDisplay_BankAccountFragment$data = {
  readonly accountOwnerName: string;
  readonly bankName: string;
  readonly lastFourDigits: string;
  readonly " $fragmentType": "bankAccountDisplay_BankAccountFragment";
};
export type bankAccountDisplay_BankAccountFragment$key = {
  readonly " $data"?: bankAccountDisplay_BankAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"bankAccountDisplay_BankAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "bankAccountDisplay_BankAccountFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastFourDigits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountOwnerName",
      "storageKey": null
    }
  ],
  "type": "BankAccount",
  "abstractKey": null
};

(node as any).hash = "2713ee466602d17e67249431aba8eb1b";

export default node;
