import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Text } from "@utopia/ui"

import { VerificationForm } from "#components/verification/verification-form.js"
import type { HubSpotConversationsType } from "#types/hubspot-conversations"

import type { rejectedVerificationForm_WorkspaceFragment$key } from "./__generated__/rejectedVerificationForm_WorkspaceFragment.graphql"
import { ContactSupportLink } from "./contact-support-link.js"

const copyTextOptions = {
  individual: {
    heading: "Unfortunately, your verification failed.",
    description: (
      <Text size="lg">
        Please <ContactSupportLink /> to learn more.
      </Text>
    ),
    submit: "Contact support",
  },
  business: {
    heading: "Unfortunately, your business verification failed.",
    description: (
      <Text size="lg">
        Please <ContactSupportLink /> to learn more.
      </Text>
    ),
    submit: "Contact support",
  },
}

const WorkspaceFragment = graphql`
  fragment rejectedVerificationForm_WorkspaceFragment on Workspace {
    workspaceType
    kycStatus @required(action: THROW) {
      email
      name
    }
  }
`

interface RejectedVerificationFormProps {
  workspace: rejectedVerificationForm_WorkspaceFragment$key
}

export const RejectedVerificationForm = ({
  workspace,
}: RejectedVerificationFormProps) => {
  const workspaceData = useFragment(WorkspaceFragment, workspace)
  const copyText = copyTextOptions[workspaceData.workspaceType]
  const defaultValues = {
    name: workspaceData.kycStatus.name,
    email: workspaceData.kycStatus.email,
  }

  const handleOpenChat = () => {
    ;(
      window as { HubSpotConversations?: HubSpotConversationsType }
    ).HubSpotConversations?.widget.open()
  }

  return (
    <VerificationForm
      type={workspaceData.workspaceType}
      copyText={copyText}
      defaultValues={defaultValues}
      inputsReadOnly
      handleSubmit={handleOpenChat}
    />
  )
}
