import InlineSVG from "react-inlinesvg"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { TableCell } from "@utopia/ui"

import type { amountSentCell_BankTransferFragment$key } from "./__generated__/amountSentCell_BankTransferFragment.graphql"
import { formatAmount, formatToken } from "./utils.js"

const BankTransferFragment = graphql`
  fragment amountSentCell_BankTransferFragment on OfframpTransfer {
    stableCoinAmountInCents
    tokenAddress
    networkId
    fiatCurrency
  }
`

interface BankTransfersTableProps {
  bankTransfer: amountSentCell_BankTransferFragment$key
}

export function AmountSentCell({ bankTransfer }: BankTransfersTableProps) {
  const bankTransferData = useFragment(BankTransferFragment, bankTransfer)
  const formattedAmount = formatAmount(bankTransferData.stableCoinAmountInCents)
  const fiatCurrencySvg = "/usd.svg"
  const formattedToken = formatToken({
    tokenAddress: bankTransferData.tokenAddress,
    networkId: bankTransferData.networkId,
  })

  return (
    <TableCell>
      <div className="flex items-center">
        {formattedAmount} {bankTransferData.fiatCurrency}
        <InlineSVG src={fiatCurrencySvg} className="h-5 w-5 p-1" />{" "}
        <span className="text-stone-500">with</span>{" "}
        {formattedToken && (
          <>
            <InlineSVG src={formattedToken.logoSrc} className="h-5 w-5 p-1 px-1" />{" "}
            {formattedToken.symbol}
          </>
        )}
      </div>
    </TableCell>
  )
}
