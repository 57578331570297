import { Route, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { sluggedRoute } from "#router.js"

import type { bankTransfers_BankTransfersQuery } from "./__generated__/bankTransfers_BankTransfersQuery.graphql.js"
import { BankTransfersQuery } from "./index.js"

export const bankTransfersRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/bank-transfers",
  wrapInSuspense: false,
  component: lazyRouteComponent(() => import("./index.js"), "BankTransfers"),
  loader: ({ params: { slug } }) => {
    return loadQuery<bankTransfers_BankTransfersQuery>(
      RelayEnvironment,
      BankTransfersQuery,
      {
        workspaceSlug: slug,
      },
    )
  },
})
