import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { Table, TableBody, TableHead, TableHeader, TableRow } from "@utopia/ui"

import type { bankTransfersTable_BankTransferFragment$key } from "./__generated__/bankTransfersTable_BankTransferFragment.graphql"
import { AmountSentCell } from "./amount-sent-cell.js"
import { RecipientCell } from "./recipient-cell.js"
import { SentAtCell } from "./sent-at-cell.js"
import { StatusCell } from "./status-cell.js"
import { TransactionFeeCell } from "./transaction-fee-cell.js"
import { WalletCell } from "./wallet-cell.js"

const BankTransfersFragment = graphql`
  fragment bankTransfersTable_BankTransferFragment on OfframpTransfer
  @relay(plural: true) {
    id
    ...walletCell_BankTransferFragment
    ...recipientCell_BankTransferFragment
    ...amountSentCell_BankTransferFragment
    ...transactionFeeCell_BankTransferFragment
    ...sentAtCell_BankTransferFragment
    ...statusCell_BankTransferFragment
  }
`

interface BankTransfersTableProps {
  bankTransfers: bankTransfersTable_BankTransferFragment$key
}

export function BankTransfersTable({ bankTransfers }: BankTransfersTableProps) {
  const bankTransfersData = useFragment(BankTransfersFragment, bankTransfers)

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Wallet</TableHead>
          <TableHead>Recipient</TableHead>
          <TableHead>Amount Sent</TableHead>
          <TableHead>Transaction Fee</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Sent Date</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {bankTransfersData.map((bankTransfer) => (
          <TableRow key={bankTransfer.id}>
            <WalletCell bankTransfer={bankTransfer} />
            <RecipientCell bankTransfer={bankTransfer} />
            <AmountSentCell bankTransfer={bankTransfer} />
            <TransactionFeeCell bankTransfer={bankTransfer} />
            <StatusCell bankTransfer={bankTransfer} />
            <SentAtCell bankTransfer={bankTransfer} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
