import { Route, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import { RelayEnvironment } from "#RelayEnvironment.js"
import { sluggedRoute } from "#router.js"

import type { verification_WorkspaceQuery } from "./__generated__/verification_WorkspaceQuery.graphql.js"
import { WorkspaceQuery } from "./index.js"

export const verificationRoute = new Route({
  getParentRoute: () => sluggedRoute,
  path: "/verification",
  wrapInSuspense: false,
  component: lazyRouteComponent(() => import("./index.js"), "Verification"),
  loader: ({ params: { slug } }) => {
    return loadQuery<verification_WorkspaceQuery>(RelayEnvironment, WorkspaceQuery, {
      workspaceSlug: slug,
    })
  },
})
