/**
 * @generated SignedSource<<8651eb407cd32c425242814b39eca049>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OfframpTransferCreateInput = {
  bankAccountId: string;
  description?: string | null;
  networkId: number;
  stableCoinAmountInCents: string;
  tokenAddress: string;
  walletAddress: string;
};
export type bankTransfersNew_CreateBankTransferMutation$variables = {
  idempotencyKey: string;
  transferDetails: OfframpTransferCreateInput;
  workspaceSlug: string;
};
export type bankTransfersNew_CreateBankTransferMutation$data = {
  readonly offrampTransferCreate: {
    readonly destinationAddress: string;
    readonly id: string;
  };
};
export type bankTransfersNew_CreateBankTransferMutation = {
  response: bankTransfersNew_CreateBankTransferMutation$data;
  variables: bankTransfersNew_CreateBankTransferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "idempotencyKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transferDetails"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSlug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "idempotencyKey",
        "variableName": "idempotencyKey"
      },
      {
        "kind": "Variable",
        "name": "transferDetails",
        "variableName": "transferDetails"
      },
      {
        "kind": "Variable",
        "name": "workspaceSlug",
        "variableName": "workspaceSlug"
      }
    ],
    "concreteType": "OfframpTransfer",
    "kind": "LinkedField",
    "name": "offrampTransferCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "destinationAddress",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransfersNew_CreateBankTransferMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransfersNew_CreateBankTransferMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bad5e428f8ec85c16c636a4aa4f406c4",
    "id": null,
    "metadata": {},
    "name": "bankTransfersNew_CreateBankTransferMutation",
    "operationKind": "mutation",
    "text": "mutation bankTransfersNew_CreateBankTransferMutation(\n  $idempotencyKey: String!\n  $transferDetails: OfframpTransferCreateInput!\n  $workspaceSlug: String!\n) {\n  offrampTransferCreate(idempotencyKey: $idempotencyKey, transferDetails: $transferDetails, workspaceSlug: $workspaceSlug) {\n    id\n    destinationAddress\n  }\n}\n"
  }
};
})();

(node as any).hash = "745053fb7d9ac8d82a58ae85b60ddcdc";

export default node;
