/**
 * @generated SignedSource<<f346aa3b238d97348ed2d5d8c12193f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BankAccountCreateInput = {
  accountNumber: string;
  accountOwnerName: string;
  address?: BankAccountAddressCreateInput | null;
  bankName: string;
  idempotencyKey: string;
  routingNumber: string;
};
export type BankAccountAddressCreateInput = {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  streetLine1: string;
  streetLine2?: string | null;
};
export type addBankAccountDialog_AddBankAccountMutation$variables = {
  input: BankAccountCreateInput;
  workspaceSlug: string;
};
export type addBankAccountDialog_AddBankAccountMutation$data = {
  readonly bankAccountCreate: {
    readonly " $fragmentSpreads": FragmentRefs<"bankAccountsTable_BankAccountFragment">;
  };
};
export type addBankAccountDialog_AddBankAccountMutation = {
  response: addBankAccountDialog_AddBankAccountMutation$data;
  variables: addBankAccountDialog_AddBankAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "workspaceSlug",
    "variableName": "workspaceSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addBankAccountDialog_AddBankAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BankAccount",
        "kind": "LinkedField",
        "name": "bankAccountCreate",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "bankAccountsTable_BankAccountFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addBankAccountDialog_AddBankAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BankAccount",
        "kind": "LinkedField",
        "name": "bankAccountCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountOwnerName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bankName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastFourDigits",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb705205c2c312f7584373db3b6b7db2",
    "id": null,
    "metadata": {},
    "name": "addBankAccountDialog_AddBankAccountMutation",
    "operationKind": "mutation",
    "text": "mutation addBankAccountDialog_AddBankAccountMutation(\n  $input: BankAccountCreateInput!\n  $workspaceSlug: String!\n) {\n  bankAccountCreate(input: $input, workspaceSlug: $workspaceSlug) {\n    ...bankAccountsTable_BankAccountFragment\n  }\n}\n\nfragment bankAccountsTable_BankAccountFragment on BankAccount {\n  id\n  accountOwnerName\n  bankName\n  lastFourDigits\n}\n"
  }
};
})();

(node as any).hash = "9892e4e8f1971f828c6789d8ad8fcbfc";

export default node;
